import React from "react";
import styled from "styled-components";
import AppleLogo from "../../content/assets/icons/apple-logo.svg";

interface AppleSSOButtonProps {
  text?: string;
  onClick?: () => void;
  size?: "small" | "medium";
}

export const AppleSSOButton: React.FC<AppleSSOButtonProps> = ({
  text,
  onClick,
  size = "medium",
}) => {
  return (
    <StyledButton size={size} onClick={onClick}>
      <img src={AppleLogo} alt="apple" />
      {text}
    </StyledButton>
  );
};

AppleSSOButton.defaultProps = {
  text: "Sign in with Apple",
};

const StyledButton = styled.button<{ size: "small" | "medium" }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ size }) => (size === "medium" ? "12px" : "6px")};
  width: ${({ size }) => (size === "medium" ? "327px" : "185px")};
  max-width: 100%;
  padding: ${({ size }) => (size === "medium" ? "14px" : "3px")};
  border-radius: 32px;
  background: #000;
  color: #fff;
  line-height: ${({ size }) => (size === "medium" ? "inherit" : "22px")};
  font-weight: ${({ size }) => (size === "medium" ? "inherit" : "500")};
`;
