import React from "react";
import styled from "styled-components";
import GoogleLogo from "../../content/assets/icons/google-logo.svg";

interface GoogleSSOButtonProps {
  text?: string;
  onClick?: () => void;
  size?: "small" | "medium";
}

export const GoogleSSOButton: React.FC<GoogleSSOButtonProps> = ({
  text,
  onClick,
  size = "medium",
}) => {
  return (
    <StyledButton size={size} onClick={onClick}>
      <img src={GoogleLogo} alt="google" />
      {text}
    </StyledButton>
  );
};

GoogleSSOButton.defaultProps = {
  text: "Sign in with Google",
};

const StyledButton = styled.button<{ size: "small" | "medium" }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ size }) => (size === "medium" ? "12px" : "6px")};
  width: ${({ size }) => (size === "medium" ? "327px" : "185px")};
  max-width: 100%;
  padding: ${({ size }) => (size === "medium" ? "14px" : "3px")};
  border-radius: 32px;
  border: 2px solid #e3e4e5;
  background-color: #fff;
  line-height: ${({ size }) => (size === "medium" ? "inherit" : "22px")};
  font-weight: ${({ size }) => (size === "medium" ? "inherit" : "500")};
  font-size: ${({ size }) => (size === "medium" ? "inherit" : "17px")};
  height: ${({ size }) => (size === "medium" ? "inherit" : "29px")};
`;
